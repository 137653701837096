import { LoadingButton } from "@mui/lab";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import {
  CreateGameBundleInput,
  CreateGameVersionInput,
  GameVersion,
} from "../../../generated";

export const GameVersionForm = ({
  initialValues,
  onSubmit,
  loading,
  type,
}: {
  initialValues: Partial<CreateGameVersionInput>;
  onSubmit: (values: GameVersion) => void;
  type: "create" | "update";
  loading: boolean;
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: onSubmit,
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit();
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label="Name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            size="small"
            fullWidth
            label="Slug"
            name="slug"
            disabled={type === "update"}
            value={formik.values.slug}
            onChange={formik.handleChange}
            error={formik.touched.slug && Boolean(formik.errors.slug)}
            helperText={formik.touched.slug && formik.errors.slug}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label="iOS Version"
            name="iosVersion"
            value={formik.values.iosVersion}
            onChange={formik.handleChange}
            error={
              formik.touched.iosVersion && Boolean(formik.errors.iosVersion)
            }
            helperText={formik.touched.iosVersion && formik.errors.iosVersion}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label="Android Version"
            name="androidVersion"
            value={formik.values.androidVersion}
            onChange={formik.handleChange}
            error={
              formik.touched.androidVersion &&
              Boolean(formik.errors.androidVersion)
            }
            helperText={
              formik.touched.androidVersion && formik.errors.androidVersion
            }
          ></TextField>
        </Grid>

        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label="iOS Store Url"
            name="iosStoreUrl"
            value={formik.values.iosStoreUrl}
            onChange={formik.handleChange}
            error={
              formik.touched.iosStoreUrl && Boolean(formik.errors.iosStoreUrl)
            }
            helperText={formik.touched.iosStoreUrl && formik.errors.iosStoreUrl}
          ></TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            size="small"
            label="Android Store Url"
            name="androidStoreUrl"
            value={formik.values.androidStoreUrl}
            onChange={formik.handleChange}
            error={
              formik.touched.androidStoreUrl &&
              Boolean(formik.errors.androidStoreUrl)
            }
            helperText={
              formik.touched.androidStoreUrl && formik.errors.androidStoreUrl
            }
          ></TextField>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton loading={loading} variant={"contained"} type="submit">
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
