import { gql } from "@apollo/client";

export const UPDATE_GAME_VERSION = gql`
  mutation UpdateGameVersion($id: String!, $updates: UpdateGameVersionInput!) {
    updateGameVersion(id: $id, updates: $updates) {
      id
    }
  }
`;

export const CREATE_GAME_VERSION = gql`
  mutation CreateGameVersion($gameversion: CreateGameVersionInput!) {
    createGameVersion(gameversion: $gameversion) {
      id
    }
  }
`;

export const DELETE_GAME_VERSION = gql`
  mutation DeleteGameVersion($id: String!) {
    deleteGameVersion(id: $id) {
      id
    }
  }
`;

export const CREATE_GAME_BUNDLE = gql`
  mutation CreateGameBundle($gameBundle: CreateGameBundleInput!) {
    createGameBundle(gameBundle: $gameBundle) {
      id
    }
  }
`;

export const DELETE_GAME_BUNDLE = gql`
  mutation DeleteGamaBundle($id: String!) {
    deleteGameBundle(id: $id) {
      id
    }
  }
`;
