import { gql } from "@apollo/client";
import { GameBundleFields, GameVersionFields } from "./fragment";

export const GET_ALL_GAME_VERSIONS = gql`
  ${GameVersionFields}
  query GetAllGameVersions(
    $offset: Int!
    $limit: Int!
    $where: GameVersionFilterArgs
  ) {
    findAllGameVersions(offset: $offset, limit: $limit, where: $where) {
      nodes {
        ...GameVersionFields
      }
      totalCount
    }
  }
`;

export const GET_GAME_VERSION = gql`
  ${GameVersionFields}
  query GetGameVersion($id: String!) {
    findGameVersion(id: $id) {
      ...GameVersionFields
    }
  }
`;

export const GET_ALL_GAME_BUNDLES = gql`
  ${GameBundleFields}
  query GetGameBundles($gameVersionId: String!, $limit: Int!, $offset: Int!) {
    findAllGameBundles(
      gameVersionId: $gameVersionId
      limit: $limit
      offset: $offset
    ) {
      nodes {
        ...GameBundleFields
      }
      totalCount
    }
  }
`;

export const GET_BUNDLE_UPLOAD_URL = gql`
  query GetBundleUploadUrl($gameBundleId: String!) {
    getGameBundleUploadUrl(gameBundleId: $gameBundleId)
  }
`;
