import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  CreateGameBundleMutation,
  CreateGameBundleMutationVariables,
  DeleteGamaBundleMutation,
  DeleteGamaBundleMutationVariables,
  GameVersion,
  GameVersionFieldsFragment,
  GetBundleUploadUrlQuery,
  GetBundleUploadUrlQueryVariables,
  GetGameBundlesQuery,
  GetGameBundlesQueryVariables,
  GetGameVersionQuery,
} from "../../../generated";
import { CREATE_GAME_BUNDLE, DELETE_GAME_BUNDLE } from "../graphql/mutation";
import { GET_ALL_GAME_BUNDLES, GET_BUNDLE_UPLOAD_URL } from "../graphql/query";
import { GameBundleToolBar } from "./GameBundleToolBar";

export const GameBundleGrid = (props: {
  gameVersion: GameVersionFieldsFragment;
  onSetActiveGameBundle: (id: string) => void;
}) => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);
  const [activeBundleId, setActiveBundleId] = useState("");
  const { acceptedFiles, open } = useDropzone();

  const { data, loading, refetch } = useQuery<
    GetGameBundlesQuery,
    GetGameBundlesQueryVariables
  >(GET_ALL_GAME_BUNDLES, {
    variables: {
      limit,
      offset: page * limit,
      gameVersionId: props.gameVersion.id,
    },
    pollInterval: 5 * 1000,
  });

  const [createGameBundle, { loading: createGameBundleLoading }] = useMutation<
    CreateGameBundleMutation,
    CreateGameBundleMutationVariables
  >(CREATE_GAME_BUNDLE);

  const [deleteGameBundle, { loading: deleteGameBundleLoading }] = useMutation<
    DeleteGamaBundleMutation,
    DeleteGamaBundleMutationVariables
  >(DELETE_GAME_BUNDLE);

  const [getBundleUploadUrl, {}] = useLazyQuery<
    GetBundleUploadUrlQuery,
    GetBundleUploadUrlQueryVariables
  >(GET_BUNDLE_UPLOAD_URL);

  useEffect(() => {
    if (acceptedFiles.length === 1) {
      const file = _.first(acceptedFiles);
      getBundleUploadUrl({ variables: { gameBundleId: activeBundleId } }).then(
        async (result) => {
          const url = result.data.getGameBundleUploadUrl;
          axios.put(url, await file.arrayBuffer(), {
            headers: {
              "Content-Type": file.type,
            },
            onUploadProgress: (p) => {
              console.log("progress", p);
            },
          });
        }
      );
    }
  }, [acceptedFiles]);

  return (
    <DataGrid
      sx={{
        "& .active-row.Mui-selected, & .active-row, & .active-row:hover, & .active-row.Mui-selected:hover":
          {
            bgcolor: (theme) => {
              return theme.palette.success.light;
            },
          },
      }}
      rows={data?.findAllGameBundles?.nodes || []}
      rowCount={data?.findAllGameBundles?.totalCount || 0}
      components={{
        Toolbar: GameBundleToolBar,
      }}
      componentsProps={{
        toolbar: {
          onCreate: () => {
            createGameBundle({
              variables: {
                gameBundle: {
                  name: dayjs().format("YYYY-MM-DD-HH-mm-ss"),
                  gameVersionId: props.gameVersion.id,
                },
              },
            }).then(() => {
              refetch({
                limit,
                offset: page * limit,
                gameVersionId: props.gameVersion.id,
              });
            });
          },
        },
      }}
      pagination
      paginationMode="server"
      loading={loading}
      page={page}
      pageSize={limit}
      onPageChange={(p) => {
        setPage(p);
      }}
      onPageSizeChange={(p) => {
        setLimit(p);
      }}
      getRowClassName={(param) => {
        return param?.row?.id === props?.gameVersion?.activeGameBundle?.id
          ? "active-row"
          : "";
      }}
      columns={[
        {
          field: "name",
          headerName: "Name",
          width: 25,
          flex: 0.5,
        },
        {
          field: "status",
          headerName: "Status",
        },
        {
          field: "isActive",
          headerName: "Is Active",
          renderCell: (row) => {
            return row?.row?.id === props?.gameVersion?.activeGameBundle?.id
              ? "True"
              : "False";
          },
        },
        {
          field: "url",
          headerName: "URL",
          renderCell: ({ value }) => {
            return (
              <a href={value} target="_blank">
                Open
              </a>
            );
          },
        },
        { field: "createdAt", headerName: "Created At", flex: 0.8 },
        {
          field: "action",
          headerName: "Actions",
          flex: 0.8,
          renderCell: (row) => {
            return (
              <ButtonGroup size="small">
                <Button
                  onClick={() => {
                    setActiveBundleId(row.row.id);
                    open();
                  }}
                >
                  Upload
                </Button>
                <Button
                  disabled={
                    row.row.status !== "READY" ||
                    row?.row?.id === props?.gameVersion?.activeGameBundle?.id
                  }
                  onClick={() => {
                    props.onSetActiveGameBundle(row.row.id);
                  }}
                >
                  Set Active
                </Button>
                <Button
                  onClick={() => {
                    deleteGameBundle({ variables: { id: row.row.id } }).then(
                      () => {
                        refetch({
                          limit,
                          offset: page * limit,
                          gameVersionId: props.gameVersion.id,
                        });
                      }
                    );
                  }}
                  color="error"
                >
                  Delete
                </Button>
              </ButtonGroup>
            );
          },
        },
      ]}
    ></DataGrid>
  );
};
