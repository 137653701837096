import { useMutation } from "@apollo/client";
import { Card, CardHeader, Button, CardContent } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { buildErrorMessage } from "../../common/graphql/errors";
import {
  CreateGameVersionMutation,
  CreateGameVersionMutationVariables,
} from "../../generated";
import { GameVersionForm } from "./components/GameVersionForm";
import { CREATE_GAME_VERSION } from "./graphql/mutation";

export const GameVersionCreatePage = () => {
  const [createGameVersion, { data, error, loading }] = useMutation<
    CreateGameVersionMutation,
    CreateGameVersionMutationVariables
  >(CREATE_GAME_VERSION);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?.createGameVersion?.id) {
      enqueueSnackbar("Game created", { variant: "success" });
      navigate(`/game-versions/${data.createGameVersion.id}/edit`);
    }
  }, [data]);

  if (error) {
    enqueueSnackbar(buildErrorMessage(error), { variant: "error" });
  }

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader title="New Game"></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <GameVersionForm
          type="create"
          initialValues={{
            name: "",
            iosVersion: "",
            iosStoreUrl: "",
            androidVersion: "",
            androidStoreUrl: "",
            activeGameBundleId: "",
          }}
          loading={loading}
          onSubmit={(values) => {
            createGameVersion({ variables: { gameversion: values } });
          }}
        ></GameVersionForm>
      </CardContent>
    </Card>
  );
};
