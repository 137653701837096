import React, { useEffect } from "react";
import "./App.css";
import { RecoilRoot, useRecoilSnapshot, useRecoilValue } from "recoil";
import { AUTH_STATE } from "./pages/auth/state";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { client } from "./graphql";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { SnackbarProvider } from "notistack";
import { GeneralLayout } from "./layouts/GeneralLayout";
import { PublicLayout } from "./layouts/PublicLayout";
import { LoginPage } from "./pages/auth/LoginPage";
import { CallbackPage } from "./pages/auth/CallbackPage";
import { GameVersionIndexPage } from "./pages/game-version/GameVersionIndexPage";
import { GameVersionCreatePage } from "./pages/game-version/GameVersionCreatePage";
import { GameVersionEditPage } from "./pages/game-version/GameVersionPage";

function DebugObserver(): JSX.Element {
  const snapshot = useRecoilSnapshot();
  useEffect(() => {
    console.debug("The following atoms were modified:");
    for (const node of snapshot.getNodes_UNSTABLE({ isModified: true })) {
      console.debug(node.key, snapshot.getLoadable(node));
    }
  }, [snapshot]);

  return null;
}

const RequireAuth = ({ children }: { children: any }) => {
  const authState = useRecoilValue(AUTH_STATE);
  let location = useLocation();
  if (!authState.accessToken) {
    return <Navigate to={"/login"} state={{ from: location }} />;
  }
  return children;
};

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ApolloProvider client={client}>
        <RecoilRoot>
          <DebugObserver />
          <ThemeProvider theme={theme}>
            <CssBaseline></CssBaseline>
            <SnackbarProvider>
              <Router>
                <Routes>
                  <Route element={<PublicLayout></PublicLayout>}>
                    <Route
                      path="/login"
                      element={<LoginPage></LoginPage>}
                    ></Route>
                    <Route
                      path="/callback"
                      element={<CallbackPage></CallbackPage>}
                    ></Route>
                  </Route>
                  <Route
                    element={
                      <RequireAuth>
                        <GeneralLayout></GeneralLayout>
                      </RequireAuth>
                    }
                  >
                    <Route
                      path="/game-versions"
                      element={<GameVersionIndexPage></GameVersionIndexPage>}
                    ></Route>
                    <Route
                      path="/game-versions/new"
                      element={<GameVersionCreatePage></GameVersionCreatePage>}
                    ></Route>
                    <Route
                      path="/game-versions/:id/edit"
                      element={<GameVersionEditPage></GameVersionEditPage>}
                    ></Route>
                  </Route>
                  <Route
                    path="/"
                    element={<Navigate replace to="game-versions" />}
                  />
                </Routes>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </RecoilRoot>
      </ApolloProvider>
    </LocalizationProvider>
  );
}

export default App;
