import { useLazyQuery, useQuery } from "@apollo/client";
import { useCallback, useState } from "react";
import { GET_ALL_GAME_VERSIONS } from "./graphql/query";
import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  GetAllGameVersionsQuery,
  GetAllGameVersionsQueryVariables,
} from "../../generated";
import { DataGrid } from "@mui/x-data-grid";

export const GameVersionIndexPage = () => {
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(0);

  const { data, loading } = useQuery<
    GetAllGameVersionsQuery,
    GetAllGameVersionsQueryVariables
  >(GET_ALL_GAME_VERSIONS, { variables: { limit, offset: page * limit } });

  const navigate = useNavigate();

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader
        title="Games"
        action={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              navigate("/game-versions/new");
            }}
          >
            New
          </Button>
        }
      ></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        <DataGrid
          rows={data?.findAllGameVersions?.nodes || []}
          rowCount={data?.findAllGameVersions?.totalCount || 0}
          pagination
          paginationMode="server"
          loading={loading}
          page={page}
          pageSize={limit}
          onPageChange={(p) => {
            setPage(p);
          }}
          onPageSizeChange={(p) => {
            setLimit(p);
          }}
          columns={[
            { field: "id", headerName: "ID", flex: 0.5 },
            { field: "name", headerName: "Name", flex: 1 },
            { field: "slug", headerName: "Slug", flex: 1 },
            { field: "iosVersion", headerName: "iOS Version", flex: 0.5 },
            {
              field: "androidVersion",
              headerName: "Android Version",
              flex: 0.5,
            },
            { field: "updatedAt", headerName: "UpdatedAt", flex: 0.8 },
            {
              field: "edit",
              headerName: "Edit",
              flex: 0.3,
              renderCell: (row) => {
                return (
                  <Button
                    onClick={() => {
                      navigate(`/game-versions/${row.row.id}/edit`);
                    }}
                  >
                    Edit
                  </Button>
                );
              },
            },
          ]}
        ></DataGrid>
      </CardContent>
    </Card>
  );
};
