import { gql } from "@apollo/client";

export const GameVersionFields = gql`
  fragment GameVersionFields on GameVersion {
    id
    name
    slug
    iosStoreUrl
    androidStoreUrl
    iosVersion
    androidVersion
    activeGameBundle {
      id
      name
    }
    createdAt
    updatedAt
  }
`;

export const GameBundleFields = gql`
  fragment GameBundleFields on GameBundle {
    id
    name
    url
    createdAt
    updatedAt
    status
  }
`;
