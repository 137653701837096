import { Button } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid";

export const GameBundleToolBar = (props: { onCreate: () => {} }) => {
  return (
    <GridToolbarContainer>
      <Button
        variant="outlined"
        onClick={() => {
          props.onCreate();
        }}
      >
        Create Bundle
      </Button>
    </GridToolbarContainer>
  );
};
