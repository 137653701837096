import { useMutation, useQuery } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Box,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { buildErrorMessage } from "../../common/graphql/errors";
import {
  DeleteGameVersionMutation,
  DeleteGameVersionMutationVariables,
  GetGameVersionQuery,
  GetGameVersionQueryVariables,
  UpdateGameVersionMutation,
  UpdateGameVersionMutationVariables,
} from "../../generated";
import { GameBundleGrid } from "./components/GameBundleGrid";
import { GameBundleToolBar } from "./components/GameBundleToolBar";
import { GameVersionForm } from "./components/GameVersionForm";
import { DELETE_GAME_VERSION, UPDATE_GAME_VERSION } from "./graphql/mutation";
import { GET_GAME_VERSION } from "./graphql/query";

export const GameVersionEditPage = () => {
  const params = useParams();
  const { data: gameversion, loading: gameversionLoading, refetch } = useQuery<
    GetGameVersionQuery,
    GetGameVersionQueryVariables
  >(GET_GAME_VERSION, {
    variables: { id: params.id },
  });
  const [updateGameVersion, { data, error, loading }] = useMutation<
    UpdateGameVersionMutation,
    UpdateGameVersionMutationVariables
  >(UPDATE_GAME_VERSION);

  const [deleteGameVersion, { loading: deleteLoading }] = useMutation<
    DeleteGameVersionMutation,
    DeleteGameVersionMutationVariables
  >(DELETE_GAME_VERSION);

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (data?.updateGameVersion?.id) {
      enqueueSnackbar("GameVersion updated", { variant: "success" });
    }
  }, [data]);

  if (error) {
    enqueueSnackbar(buildErrorMessage(error), { variant: "error" });
  }

  return (
    <Card sx={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <CardHeader
        title="Edit Game"
        action={
          <LoadingButton
            variant="outlined"
            color="error"
            onClick={() => {
              deleteGameVersion({ variables: { id: params.id } }).then(() => {
                navigate("/game-versions");
              });
            }}
          >
            Delete
          </LoadingButton>
        }
      ></CardHeader>
      <CardContent sx={{ flex: 1 }}>
        {gameversionLoading && !gameversion?.findGameVersion ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{ display: "flex", height: "100%", flexDirection: "column" }}
          >
            <GameVersionForm
              type="update"
              initialValues={{
                ...gameversion?.findGameVersion,
                activeGameBundleId:
                  gameversion?.findGameVersion?.activeGameBundle?.id || "",
              }}
              loading={loading}
              onSubmit={(values) => {
                updateGameVersion({
                  variables: {
                    updates: {
                      name: values.name,
                      iosVersion: values.iosVersion,
                      iosStoreUrl: values.iosStoreUrl,
                      androidVersion: values.androidVersion,
                      androidStoreUrl: values.androidStoreUrl,
                    },
                    id: params.id,
                  },
                });
              }}
            ></GameVersionForm>
            <Divider sx={{ m: 2 }}></Divider>
            <GameBundleGrid
              onSetActiveGameBundle={(bundleId) => {
                updateGameVersion({
                  variables: {
                    updates: {
                      activeGameBundleId: bundleId,
                      name: gameversion.findGameVersion.name,
                    },
                    id: params.id,
                  },
                }).then(()=>{
                  refetch()
                });
              }}
              gameVersion={gameversion?.findGameVersion}
            ></GameBundleGrid>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
